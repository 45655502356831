import { clientVersionHeaderName } from '@/common/config';
import { ERROR_MESSAGES } from '@/common/messages';
import { logger } from '@/components/Analytics';
import { useLogout } from '@/components/Providers/auth/LogoutProvider';
import { useSnackbar } from 'notistack';
import { FC, PropsWithChildren, useMemo } from 'react';
import { Provider, cacheExchange, createClient, fetchExchange, mapExchange } from 'urql';
import { VERSION } from './config';
import { useIsSuperAdmin } from '@/components/Admin/useIsSuperAdmin';
import { versionChecker } from '@/components/UpdateNotification';
import { useNetworkConnection } from '../hooks/useNetworkConnection';

export const UrqlProvider: FC<PropsWithChildren> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { logout } = useLogout();
  const isSuperAdmin = useIsSuperAdmin();
  const online = useNetworkConnection();

  const client = useMemo(
    () =>
      createClient({
        url: '/api/graphql',
        requestPolicy: 'cache-and-network',
        fetchOptions: () => ({
          headers: { [clientVersionHeaderName]: VERSION },
        }),
        exchanges: [
          cacheExchange,
          mapExchange({
            onResult(result) {
              const { error } = result;
              if (error) {
                if (error.response?.status === 401) {
                  logout();
                  return;
                }

                const shouldShowError = error.graphQLErrors.some((error) => error.extensions?.showErrorInClient);
                if (shouldShowError || isSuperAdmin) {
                  const messages = error.graphQLErrors.map((error) => error.message).filter((message) => message);
                  messages.forEach((message) =>
                    enqueueSnackbar(`${!shouldShowError ? 'ADMIN: ' : ''}Error: ${message}`, { variant: 'error' })
                  );
                } else {
                  enqueueSnackbar(ERROR_MESSAGES.ERROR_MESSAGE_DEFAULT, { variant: 'error' });
                }

                if ([400, 500].includes(error.response?.status)) {
                  versionChecker.throttleCheckVersion();
                }
              }
              return result;
            },
            onError(error, operation) {
              const { query, kind, variables } = operation;

              // @ts-ignore
              const operationName = query.definitions[0]?.name?.value;
              const errorContext = {
                query: {
                  kind,
                  variables,
                  source: query.loc?.source?.body, // this is a string
                },
              };
              if (online) {
                logger.error(
                  operationName ? `UrqlProviderError: ${operationName}` : 'UrqlProviderError',
                  errorContext,
                  error
                );
              } else {
                logger.debug(
                  operationName ? `UrqlProviderError: offline: ${operationName}` : 'UrqlProviderError: offline',
                  errorContext,
                  error
                );
              }
              if (error?.response?.status !== 401 && !!error.networkError) {
                enqueueSnackbar(ERROR_MESSAGES.ERROR_MESSAGE_NETWORK, { variant: 'error' });
              }
            },
          }),
          fetchExchange,
        ],
      }),
    [enqueueSnackbar, logout]
  );

  return <Provider value={client}>{props.children}</Provider>;
};
